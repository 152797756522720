<template>
  <div class="card" v-if="report">
    <div class="card-body">
      <div class="fw-bold fs-4">
        {{
          $route.query.report_type === 'weekly'
            ? $t('__weeklyReport')
            : $t('__dailyReport')
        }}
      </div>
      <div class="mb-3">
        <BarChart
          class="svgChart"
          v-if="
            $route.query.report_type === 'daily' &&
              report.bed &&
              report.bed.status
          "
          :title="$t('__bedStatus')"
          type="daily"
          :startAt="startAt"
          :endAt="endAt"
          xKey="time"
          yKey="value"
          :svgPaddingX="svgPaddingX"
          :svgPaddingY="svgPaddingY"
          :data="Object.freeze(report.bed.status)"
          :notify="report.bed.notify"
          :chartColor="chartColor.bed"
          :repaint="repaint"
        />
        <template
          v-if="
            $route.query.report_type === 'weekly' &&
              report.status &&
              $route.query.data_type === 'bedStatus'
          "
        >
          <div class="mb-4">
            <span class="fs-5 fw-bold d-block d-sm-inline-block">{{
              $t('__bedStatusWeeklyReport')
            }}</span>
            <span
              class="pe-2 ps-sm-3 fw-bold d-inline-block"
              v-for="(item, key) in chartColor.bed"
              :key="key"
            >
              <template v-if="key !== 'null'">
                <span
                  :style="{ color: item }"
                  :class="key === 'notify' ? 'fs-4' : ''"
                >
                  <svg
                    v-if="key === 'notify'"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-caret-down-fill"
                    viewBox="0 0 16 16"
                  >
                    <polygon points="0,0 7,14 14,0" />
                  </svg>
                  <svg
                    v-else-if="key === 'offLine'"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-caret-down-fill"
                    viewBox="0 0 16 16"
                    stroke="#333"
                  >
                    <polygon points="0,0 7,14 14,0" />
                  </svg>
                  <svg
                    v-else
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-circle-fill"
                    viewBox="0 0 16 16"
                  >
                    <circle cx="7" cy="7" r="7" />
                  </svg>
                </span>
                <span>{{ $t('__' + key) }}</span>
              </template>
              <span v-if="key === 'notify'">{{
                '【' +
                  $t('__LYBNotifyText') +
                  '】、【' +
                  $t('__LVB') +
                  '】、【' +
                  $t('__leaveTheBedTimeOut') +
                  '】'
              }}</span>
            </span>
          </div>
          <BarChart
            v-for="i in 7"
            :key="'weeklyDate' + i"
            type="weekly"
            :startAt="weeklyStarAt(i)"
            :endAt="weeklyEndAt(i)"
            :title="weeklyTitle(i)"
            class="svgChart"
            xKey="time"
            yKey="value"
            :svgPaddingX="svgPaddingX"
            :svgPaddingY="svgPaddingY"
            :statistics="report.statistics['day_' + (i - 1)]"
            :data="Object.freeze(report.status['day_' + (i - 1)])"
            :notify="
              report.notify &&
              report.notify['day_' + (i - 1)] &&
              report.notify['day_' + (i - 1)].length
                ? report.notify['day_' + (i - 1)]
                : null
            "
            :chartColor="chartColor.bed"
            :repaint="repaint"
          />
        </template>
      </div>
      <div class="mb-3">
        <LineChart
          class="svgChart"
          v-if="
            $route.query.report_type === 'daily' &&
              report.respiration &&
              report.respiration.status
          "
          type="daily"
          :startAt="startAt"
          :endAt="endAt"
          :title="$t('__rrValue')"
          xKey="time"
          yKey="value"
          :svgPaddingX="svgPaddingX"
          :svgPaddingY="svgPaddingY"
          :data="Object.freeze(report.respiration.status)"
          :notify="report.respiration.notify"
          lineColor="#6966D8"
          :chartColor="chartColor.respiration"
          :repaint="repaint"
          :notifyMax="
            notifyData && notifyData.notify_respiration
              ? notifyData.notify_respiration_max
              : 0
          "
          :notifyMin="
            notifyData && notifyData.notify_respiration
              ? notifyData.notify_respiration_min
              : 0
          "
        />
        <template
          v-if="
            $route.query.report_type === 'weekly' &&
              report.status &&
              $route.query.data_type === 'RR'
          "
        >
          <div class="mb-4">
            <span class="fs-5 fw-bold d-block d-sm-inline-block">{{
              $t('__respirationRateWeeklyReport')
            }}</span>
            <span
              class="pe-2 ps-sm-3 fw-bold d-inline-block"
              v-for="(item, key) in chartColor.respiration"
              :key="key"
            >
              <span
                :style="{ color: item }"
                :class="key === 'notify' ? 'fs-4' : ''"
              >
                <svg
                  v-if="key === 'notify'"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-caret-down-fill"
                  viewBox="0 0 16 16"
                >
                  <polygon points="0,0 7,14 14,0" />
                </svg>
                <svg
                  v-else
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-circle-fill"
                  viewBox="0 0 16 16"
                >
                  <circle cx="7" cy="7" r="7" />
                </svg>
              </span>
              <span>{{ $t('__' + key) }}</span>
              <span v-if="key === 'notify'">{{
                '【' + $t('__RR') + '】'
              }}</span>
            </span>
            <span class="pe-2 ps-sm-3 fw-bold d-inline-block">
              <span
                class="d-inline-block"
                style="color: rgba(253, 97, 97, 0.35)"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-circle-fill"
                  viewBox="0 0 16 16"
                >
                  <circle cx="7" cy="7" r="7" />
                </svg>
              </span>
              <span class="d-inline-block">{{ $t('__notifyRange') }}</span>
            </span>
          </div>
          <LineChart
            v-for="i in 7"
            class="svgChart"
            :key="'weeklyDate' + i"
            type="weekly"
            :startAt="weeklyStarAt(i)"
            :endAt="weeklyEndAt(i)"
            :title="weeklyTitle(i)"
            xKey="time"
            yKey="value"
            :svgPaddingX="svgPaddingX"
            :svgPaddingY="svgPaddingY"
            :statistics="report.statistics['day_' + (i - 1)]"
            :data="Object.freeze(report.status['day_' + (i - 1)])"
            :notify="
              report.notify &&
              report.notify['day_' + (i - 1)] &&
              report.notify['day_' + (i - 1)].length
                ? report.notify['day_' + (i - 1)]
                : null
            "
            lineColor="#6966D8"
            :chartColor="chartColor.respiration"
            :repaint="repaint"
            :notifyMax="
              notifyData && notifyData.notify_respiration
                ? notifyData.notify_respiration_max
                : 0
            "
            :notifyMin="
              notifyData && notifyData.notify_respiration
                ? notifyData.notify_respiration_min
                : 0
            "
          />
        </template>
      </div>
      <div>
        <LineChart
          class="svgChart"
          v-if="
            $route.query.report_type === 'daily' &&
              report.restless &&
              report.restless.status
          "
          type="daily"
          :startAt="startAt"
          :endAt="endAt"
          :title="$t('__restlessnessValue')"
          xKey="time"
          yKey="value"
          :svgPaddingX="svgPaddingX"
          :svgPaddingY="svgPaddingY"
          :data="Object.freeze(report.restless.status)"
          :notify="report.restless.notify"
          lineColor="#07CD32"
          :chartColor="chartColor.restless"
          :repaint="repaint"
        />
        <template
          v-if="
            $route.query.report_type === 'weekly' &&
              report.status &&
              $route.query.data_type === 'restless'
          "
        >
          <div class="mb-4">
            <span class="fs-5 fw-bold d-block d-sm-inline-block">{{
              $t('__restlessWeeklyReport')
            }}</span>
            <span
              class="pe-2 ps-sm-3 fw-bold d-inline-block"
              v-for="(item, key) in chartColor.restless"
              :key="key"
            >
              <span
                :style="{ color: item }"
                :class="key === 'notify' ? 'fs-4' : ''"
              >
                <svg
                  v-if="key === 'notify'"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-caret-down-fill"
                  viewBox="0 0 16 16"
                >
                  <polygon points="0,0 7,14 14,0" />
                </svg>
                <svg
                  v-else
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-circle-fill"
                  viewBox="0 0 16 16"
                >
                  <circle cx="7" cy="7" r="7" />
                </svg>
              </span>
              <span>{{ $t('__' + key) }}</span>
              <span v-if="key === 'notify'">{{
                '【' + $t('__restless') + '】'
              }}</span>
            </span>
          </div>
          <LineChart
            v-for="i in 7"
            class="svgChart"
            :key="'weeklyDate' + i"
            type="weekly"
            :startAt="weeklyStarAt(i)"
            :endAt="weeklyEndAt(i)"
            :title="weeklyTitle(i)"
            xKey="time"
            yKey="value"
            :svgPaddingX="svgPaddingX"
            :svgPaddingY="svgPaddingY"
            :data="Object.freeze(report.status['day_' + (i - 1)])"
            :notify="
              report.notify &&
              report.notify['day_' + (i - 1)] &&
              report.notify['day_' + (i - 1)].length
                ? report.notify['day_' + (i - 1)]
                : null
            "
            lineColor="#07CD32"
            :chartColor="chartColor.restless"
            :repaint="repaint"
          />
        </template>
      </div>
      <div
        v-if="
          $route.query.report_type === 'daily' &&
            !(report.bed && report.bed.status) &&
            !(report.restless && report.restless.status) &&
            !(report.respiration && report.respiration.status)
        "
        class="text-center fs-5 my-3"
      >
        {{ $t('__notData') }}
      </div>
      <div
        v-if="$route.query.report_type === 'weekly' && report && !report.status"
        class="text-center fs-5 my-3"
      >
        {{ $t('__notData') }}
      </div>
    </div>
  </div>
</template>

<script>
import BarChart from '@/components/chart/BarChart.vue'
import LineChart from '@/components/chart/LineChart.vue'
import { mapState, mapMutations } from 'vuex'
import 'vue2-timepicker/dist/VueTimepicker.css'
import { notify } from '@/http/api/notify.js'

export default {
  name: 'Graphical',
  components: { BarChart, LineChart },
  props: ['report', 'repaint', 'residentID', 'startAt', 'endAt'],
  data () {
    return {
      svgPaddingX: 60,
      svgPaddingY: 40,
      chartColor: {
        bed: {
          null: '#FFF',
          SIT: '#48EBDC',
          LYB: '#09A2B7',
          SLP: '#FFBC3A',
          LVB: '#C4C4C4',
          notify: '#FD6161',
          offLine: '#FFF'
        },
        respiration: {
          rrValue: '#6966D8',
          notify: '#FD6161'
        },
        restless: {
          restlessnessValue: '#07CD32'
        }
      },
      notifyData: null
    }
  },
  computed: {
    ...mapState(['token', 'timezone'])
  },
  watch: {
    'token.headers.Authorization' (data) {
      if (data) {
        this.getNotify()
      }
    }
  },
  methods: {
    ...mapMutations([
      'LoadingText',
      'Loading',
      'Loaded',
      'AlertsInfo',
      'Alerted'
    ]),
    getNotify () {
      const vm = this
      notify.get(vm.residentID, vm.token).then(res => {
        if (res.status <= 201 && res.data.status === 'success') {
          const data = res.data.data.notify
          vm.notifyData = data
        }
      })
    },
    weeklyStarAt (day) {
      const vm = this
      return new Date(new Date(vm.endAt)).addDays(-day).getTime()
    },
    weeklyEndAt (day) {
      const vm = this
      return new Date(new Date(vm.endAt)).addDays(-day + 1).getTime()
    },
    weeklyTitle (day) {
      const vm = this
      return (
        vm
          .$moment(new Date(vm.endAt).addDays(-day).getTime())
          .tz(vm.timezone)
          .format('LLLL') +
        ' ~ ' +
        vm
          .$moment(new Date(vm.endAt).addDays(-day + 1).getTime())
          .tz(vm.timezone)
          .format('LLLL')
      )
    }
  },
  created () {},
  mounted () {
    const vm = this
    if (vm.token.headers.Authorization) {
      vm.getNotify()
    }
  }
}
</script>

<style lang="scss">
.current-data-item {
  width: 138px;
  height: 138px;
  border: 10px solid rgba(#999, 0.13);
  border-radius: 100%;
  text-align: center;
  @include media-breakpoint-down(sm) {
    width: 110px;
    height: 110px;
  }
}
.svg-mouse-over {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% - 2rem);
  height: 100%;
}
</style>
